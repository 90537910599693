<template>
  <div ref="warningFourData" style="width: 100%;height: 100%;"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  methods: {
    initChart(xData, yData1, yData2, yData3, yData4) {
      if (yData1.length === 1) {
        yData1.push(0)
      }
      if (yData2.length === 1) {
        yData2.push(0)
      }
      if (yData3.length === 1) {
        yData3.push(0)
      }
      if (yData4.length === 1) {
        yData4.push(0)
      }
      let getChart = echarts.init(this.$refs.warningFourData);
      let option = {
        tooltip: {
          trigger: 'axis',
        },
        grid: { //调整图表上下左右位置
          top: '10%',
          left: '3%',
          right: '3%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          offset: 10,
          boundaryGap: true,
          data: xData,
          axisLine: { //x轴线
            lineStyle: {
              color: '#ECECEC'
            }
          },
          axisLabel: { //x轴文字
            show: true,
            textStyle: {
              fontSize: 14,
              color: '#84858B'
            }
          },
          axisTick: { //刻度线
            "show": false
          }
        },
        yAxis: {
          type: 'value',
          offset: 0,
          axisLabel: {
            show: true,
            textStyle: {
              color: '#84858B'
            }
          },
          splitLine: { //网格线
            show: true,
            lineStyle: {
              color: ['#ECECEC'],
              width: 1,
              type: 'dashed'
            }
          }
        },
        series: [{
          name: '心率预警',
          type: 'line',
          // symbol: 'none', //这句就是去掉点的
          smooth: false, //这句就是让曲线变平滑的
          data: yData1,
          itemStyle: {
            normal: {
              color: '#4C7BF8', //折点颜色
              lineStyle: {
                color: '#4C7BF8' //折线颜色
              }
            }
          }
        }, {
          name: '血压预警',
          type: 'line',
          // symbol: 'none', //这句就是去掉点的
          smooth: false, //这句就是让曲线变平滑的
          data: yData2,
          itemStyle: {
            normal: {
              color: '#27e08a', //折点颜色
              lineStyle: {
                color: '#27e08a' //折线颜色
              }
            }
          }
        }, {
          name: '血氧预警',
          type: 'line',
          // symbol: 'none', //这句就是去掉点的
          smooth: false, //这句就是让曲线变平滑的
          data: yData3,
          itemStyle: {
            normal: {
              color: '#f19323', //折点颜色
              lineStyle: {
                color: '#f19323' //折线颜色
              }
            }
          }
        }, {
          name: '护航预警',
          type: 'line',
          // symbol: 'none', //这句就是去掉点的
          smooth: false, //这句就是让曲线变平滑的
          data: yData4,
          itemStyle: {
            normal: {
              color: '#eb3b3b', //折点颜色
              lineStyle: {
                color: '#eb3b3b' //折线颜色
              }
            }
          }
        }]
      };
      getChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        setTimeout(() => {
          getChart.resize();
        }, 500)
      });
    },
  }
}
</script>
