<template>
  <div ref="warningData" style="width: 100%;height: 100%;"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  methods: {
    initChart(xData, yData1) {
      let getChart = echarts.init(this.$refs.warningData);
      let option = {
        tooltip: {
          trigger: 'axis',
        },
        grid: { //调整图表上下左右位置
          top: '10%',
          left: '7%',
          right: '5%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          offset: 10,
          boundaryGap: true,
          data: xData,
          axisLine: { //x轴线
            lineStyle: {
              color: '#ECECEC'
            }
          },
          axisLabel: { //x轴文字
            show: true,
            textStyle: {
              fontSize: 14,
              color: '#84858B'
            }
          },
          axisTick: { //刻度线
            "show": false
          }
        },
        yAxis: {
          type: 'value',
          offset: 20,
          axisLabel: {
            show: true,
            textStyle: {
              color: '#84858B'
            }
          },
          splitLine: { //网格线
            show: true,
            lineStyle: {
              color: ['#ECECEC'],
              width: 1,
              type: 'dashed'
            }
          }
        },
        series: [{
          name: '数量',
          type: 'bar',
          barWidth: 50,
          symbol: 'none', //这句就是去掉点的
          smooth: true, //这句就是让曲线变平滑的
          data: yData1,
          itemStyle: {
            normal: {
              color: '#4C7BF8', //折点颜色
              lineStyle: {
                color: '#4C7BF8' //折线颜色
              }
            }
          },
          areaStyle: { // 折现下是否填充
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: '#D5EBFF' // 0% 处的颜色
              }, {
                offset: 0.5,
                color: '#FFFFFF' // 100% 处的颜色
              }],
              global: false
            }
          },
        }]
      };
      getChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        setTimeout(() => {
          getChart.resize();
        }, 500)
      });
    },
  }
}
</script>
